import logo from './logo.svg';
import './App.css';
import React from 'react';

function App() {
  const fruits = ['jabłka', 'pomarańcze', 'gruszki'];

  return (
      <div>
        <h1>Lista owoców</h1>
        <ul>
          {fruits.map((fruit, index) => (
              <li key={index}>{fruit}</li>
          ))}
        </ul>
      </div>
  );
}

export default App;
